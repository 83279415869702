<template>
  <div
    v-if="
      getUser.role.slug === 'business-developer-pro' ||
      getUser.role.slug === 'rh' ||
      getUser.role.slug === 'assistant-rh' ||
      getUser.role.slug === 'super-administrateur'
    "
  >
    <b-card>
      <b-card-header>
        <h5>Filtrages</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="10" lg="10" class="mb-md-0 mb-2">
            <b-row>
              <b-col cols="12" sm="6" md="4" lg="4">
                <label for="">Employée</label>
                <v-select
                  v-model="payloadFilter.employee_id"
                  :options="employeeOptions"
                  class="invoice-filter-select"
                  label="name"
                  :reduce="(employee) => employee.id"
                >
                  <template v-slot:no-options>
                    <b-spinner
                      v-if="isEmployeesWithoutPaginationLoading"
                      style="width: 2.5rem; height: 2.5rem"
                      class="align-middle text-info"
                    />
                    <span v-else class="mt-1 font-medium-1"
                      >Aucun employée ne correspond</span
                    >
                  </template>
                </v-select>
              </b-col>
              <b-col cols="12" sm="6" md="4" lg="4">
                <label for="">Service</label>
                <v-select
                  v-model="payloadFilter.service_id"
                  :options="recurringServicesOption"
                  class="invoice-filter-select"
                  label="name"
                  :reduce="(service) => service.id"
                >
                  <template v-slot:no-options>
                    <b-spinner
                      v-if="isServicesWithoutPaginationLoading"
                      style="width: 2.5rem; height: 2.5rem"
                      class="align-middle text-info"
                    />
                    <span v-else class="mt-1 font-medium-1"
                      >Aucun service ne correspond</span
                    >
                  </template>
                </v-select>
              </b-col>
              <b-col cols="12" sm="6" md="3" lg="3">
                <label for="">Status</label>
                <v-select
                  v-model="payloadFilter.status"
                  :options="statusOptions"
                  class="invoice-filter-select"
                  :reduce="(item) => item.value"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" sm="6" md="2" lg="2" class="mt-2">
            <b-button
              variant="primary"
              @click="applyFilterEmployeesUnformedAction()"
            >
              <div v-if="isReLoadFilterDataUnformedEmployee">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>

              <span v-else class="text-nowrap font-medium-1">Filtrer</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card title="Liste des employées en formation">
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-start mb-2"
        >
          <b-button
            variant="primary"
            @click="reLoadDataUnformedEmployeesAction()"
          >
            <div v-if="isReLoadDataUnformedEmployees">
              <span> Chargement ... </span>
              <b-spinner small />
            </div>
            <span v-else class="text-nowrap font-medium-1">Actualiser</span>
          </b-button>
        </b-col>
      </b-row>

      <vue-good-table
        :columns="columns"
        :rows="rows"
        :is-loading="isUnformedEmployeesLoading"
      >
        <template slot="emptystate">
          <div class="text-center">
            <span>
              {{ isUnformedEmployeesLoading ? "" : "Liste vide" }}
            </span>
          </div>
        </template>

        <template slot="loadingContent">
          <div class="text-center text-info">
            <b-spinner class="align-middle" /> &nbsp;
            <span class="align-middle">Chargement... </span>
          </div>
        </template>

        <template slot="table-row" slot-scope="props">
          <div
            v-if="props.column.field === 'numbers_days_of_formation'"
            class="text-nowrap text-center align-middle"
          >
            <span class="d-block text-nowrap text-info font-small-5">
              {{
                props.row.numbers_days_of_formation
                  ? props.row.numbers_days_of_formation
                  : "---"
              }}
              jours
            </span>
          </div>

          <div
            v-else-if="props.column.field === 'setStatus'"
            class="text-nowrap text-center align-middle"
          >
            <b-badge
              pill
              :variant="`light-${resolveProStatusVariant(props.row.status)}`"
              class="text-capitalize font-small-5"
            >
              {{ props.row.setStatus }}
            </b-badge>
          </div>

          <div
            v-else-if="props.column.field === 'services'"
            class="text-nowrap text-center align-middle"
          >
            <ul>
              <li v-for="(item, index) in props.row.services" :key="index">
                <span class="text-info font-small-5">
                  {{ item.name }}
                </span>
              </li>
            </ul>
          </div>

          <div
            v-if="props.column.field === 'employee'"
            class="text-nowrap text-center align-middle"
          >
            <span class="d-block text-nowrap text-info font-small-5">{{
              props.row.full_name
            }}</span>

            <span class="text-success font-small-4">{{
              props.row.phone_number
            }}</span>
          </div>

          <div
            v-else-if="props.column.field === 'actions'"
            class="align-middle text-center"
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                    class="text-info align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  :id="`details${props.row.id}`"
                  class="cursor-pointer"
                  @click="
                    showFormationModal(props.row)
                    formationId = props.row.id;
                  "
                  v-if="props.row.status === 1"
                >
                  <feather-icon
                    icon="CheckSquareIcon"
                    class="text-success mr-1"
                    size="25"
                  />
                  <span>Valider sa formation</span>
                </b-dropdown-item>
                <b-dropdown-item
                  :id="`details${props.row.id}`"
                  class="cursor-pointer"
                  @click="
                    addServiceModal(props.row)
                    formationId = props.row.id;
                  "
                  v-if="props.row.status === 1"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="text-warning mr-1"
                    size="25"
                  />
                  <span>Ajouter d'autres services</span>
                </b-dropdown-item>
                <b-dropdown-item
                  :id="`details${props.row.id}`"
                  class="cursor-pointer"
                  @click="
                    deleteService(props.row)
                    formationId = props.row.id;
                  "
                  v-if="props.row.status === 1"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="text-danger mr-1"
                    size="25"
                  />
                  <span>Supprimer des services</span>
                </b-dropdown-item>

                <b-dropdown-item
                  :id="`details${props.row.id}`"
                  class="cursor-pointer"
                  @click="certificatPreview(props.row.certificate)"
                  v-if="props.row.status === 2"
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="text-success mr-1"
                    size="25"
                  />
                  <span>Voir le certificat</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </div>
        </template>
      </vue-good-table>
      <div class="d-flex justify-content-between mt-2 flex-wrap">
        <b-button
          :disabled="metaData.prev_page_url == null"
          variant="outline-secondary"
          @click="applyLoadMoreUnformedEmployeesAction(metaData.prev_page_url)"
        >
          <span class="text-white text-nowrap font-medium-2"
            >Page précédente</span
          >
        </b-button>

        <div>
          <span class="text-white font-medium-2"
            >{{ metaData.current_page }} sur
            {{ metaData.last_page }} pages</span
          >
        </div>
        <div>
          <span class="text-white font-medium-2">Total : {{ total }} </span>
        </div>
        <b-button
          :disabled="metaData.next_page_url == null"
          variant="outline-secondary"
          @click="applyLoadMoreUnformedEmployeesAction(metaData.next_page_url)"
        >
          <span class="text-nowrap font-medium-1 text-white"
            >Page suivante</span
          >
        </b-button>
      </div>
    </b-card>

    <b-modal
      id="modal-show-feedback-form"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-table-lite
        responsive
        :items="[{ feedback: currentOrder.feedback }]"
        :fields="[
          {
            key: 'feedback',
            label: 'Feedback du CA',
            sortable: true,
            class: 'text-center font-medium-2',
          },
        ]"
      >
        <template #cell(feedback)="data">
          <b-card-text
            class="font-weight-bold mb-25 font-medium-1 text-warning"
          >
            {{ currentOrder.feedback }}
          </b-card-text>
        </template>
      </b-table-lite>
    </b-modal>

    <b-modal
      id="modal-show-employee-profile"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-table-lite
        responsive
        :items="[
          {
            full_name: formedEmployee.full_name,
            age: formedEmployee.age,
            marital_status: formedEmployee.marital_status,
            degree: formedEmployee.degree,
            is_in_ylomi_program: formedEmployee.is_in_ylomi_program,
            nationality: formedEmployee.nationality,
          },
        ]"
        :fields="[
          {
            key: 'full_name',
            label: 'Nom & Prénom',
            sortable: true,
          },
          { key: 'age', label: 'Age', sortable: true },
          {
            key: 'marital_status',
            label: 'Situation matrimonial',
            sortable: true,
          },
          { key: 'degree', label: 'Diplome', sortable: true },
          {
            key: 'is_in_ylomi_program',
            label: 'Programme YLOMI',
            sortable: true,
          },
          { key: 'nationality', label: 'Nationnalité', sortable: true },
        ]"
      >
        <template #cell(is_in_ylomi_program)="data">
          <b-card-text
            class="font-weight-bold mb-25 font-medium-1 text-warning"
          >
            {{
              data.item.is_in_ylomi_program ? "Participe" : "Ne participe pas"
            }}
          </b-card-text>
        </template>
      </b-table-lite>
    </b-modal>

    <b-modal
      id="modal-formation"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      title="Fin de formation"
    >
      <validation-observer #default="{}" ref="endTrainingForm">
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @reset.prevent="hideModal"
          @submit.prevent="envoi()"
        >
          <validation-provider
            #default="{ errors }"
            name="nombre de jours de la formaation"
            rules="required"
          >
            <b-form-group
              label="Nombre de jours  de la formation de l'employé"
              label-for="numbers_days_of_formation"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="numbers_days_of_formation"
                v-model="numbers_days_of_formation"
                placeholder="Entrer le nombre de jours qu'a duré la formation"
                type="number"
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group
            label="L'employée à effectué une formation pratique ?"
            label-for="practice_formation_received"
          >
            <validation-provider
              #default="{ errors }"
              name="Formation pratique effectué"
              rules="required"
            >
              <b-form-select
                id="practice_formation_received"
                v-model="practice_formation_received"
                :options="[
                  { value: 1, text: 'Oui' },
                  { value: 0, text: 'Non' },
                ]"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <validation-provider
            #default="{ errors }"
            name="observation"
            rules="required"
          >
            <b-form-group
              label="Observation sur la formation de l'employé"
              label-for="observe"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-textarea
                id="observe"
                v-model="observation"
                placeholder="Laissez une observation sur la formation de l'employé"
                rows="3"
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <span >Voir les détails</span>
            </b-button>
            <b-button
              type="reset"
              variant="outline-secondary"
              @click="hideModal"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

     <!-- DETAILS Salaire employé Modal -->
     <b-modal
          id="modal-detail-end-formation"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          :title="`Détail de la validation de la formation`"
        >
        
          <div class="justify-content-center" >
            <b-row>
              <b-col md="12">
                 
                <div >
                  <b-row>
                    <b-col>
                      <span class="text-white"> Services: </span>
                    </b-col>
                    <b-col>
                      <span v-for="service in formationData.services" :key="service.id">
                        <strong class="text-info"> {{ service.name }}, </strong>
                      </span>
                    </b-col>
                  </b-row>
                  <br />  
                </div>   
                <b-row>
                  <b-col>
                    <span class="text-white"> Nombre de jours : </span>
                  </b-col>
                  <b-col>
                    <span>
                      <strong class="text-info"> {{ numbers_days_of_formation }} </strong>
                    </span>
                  </b-col>
                </b-row>
                <br />
              <div >
                <b-row >
                  <b-col>
                    <span class="text-white"> Formation pratique : </span>
                  </b-col>
                  <b-col>
                    <span>
                      <strong class="text-info">
                        {{ practice_formation_received == 1 ? 'Oui' : 'Non'}} </strong
                      >
                    </span>
                  </b-col>
                </b-row>
                <br />
              </div>
                <div >
                  <b-row>
                    <b-col>
                      <span class="text-white"> Observation: </span>
                    </b-col>
                    <b-col>
                      <span>
                        <strong class="text-info">
                          {{ observation}} </strong
                        >
                      </span>
                    </b-col>
                  </b-row>
                  <br />  
                </div>
                
                
              </b-col>
            </b-row>
            <br />
          </div>
          <hr />
          <div class="d-flex mt-2">
            <b-button
              :disabled="isMarkAsFormedLoading"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="applyMarkEmployeeAsFormedAction()"
            >
              <div v-if="isMarkAsFormedLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Valider la formation</span>
            </b-button>
            <b-button
              variant="outline-secondary"
              @click="$bvModal.hide('modal-detail-end-formation')"
            >
              Annuler
            </b-button>
          </div>
          <!-- <b-button size="sm" variant="success" class="text-center" @click="applyMarkEmployeeAsFormedAction()" >
            Envoyer au client
          </b-button> -->
    
    </b-modal>

    <b-modal
      id="modal-add-service"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      title="Ajout de services à la formation"
    >
      <validation-observer #default="{}" ref="endTrainingForm">
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @reset.prevent="hideModalAddService()"
          @submit.prevent="applyAddService()"
        >
          <validation-provider
            #default="{ errors }"
            name="Service à ajouter"
            rules="required"
          >
          <label for="">Service</label>
            <v-select
              id="role"
              v-model="payloadAddService.services"
              :reduce="(service) => service.id"
              label="name"
              :options="servicesOption"
              class="invoice-filter-select"
              multiple
            >
              <template v-slot:no-options>
                <b-spinner
                  v-if="isServiceLoading"
                  style="width: 2.5rem; height: 2.5rem"
                  class="align-middle text-info"
                />

                <span v-else class="mt-1 font-medium-1"
                  >Aucun service non envoyé en formation
                </span>
              </template>
            </v-select>
          </validation-provider>
          
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isAddServiceLoading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <div v-if="isAddServiceLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Ajouter</span>
            </b-button>
            <b-button
              type="reset"
              variant="outline-secondary"
              @click="$bvModal.hide('modal-formation')"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

   

    <b-modal
      id="modal-delete-service"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      title="Suppression de services à la formation"
    >
      <validation-observer #default="{}" ref="endTrainingForm">
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @reset.prevent="hideModalDeleteService()"
          @submit.prevent="applyDeleteService()"
        >
          <validation-provider
            #default="{ errors }"
            name="Service à supprimer"
            rules="required"
          >
          <label for="">Service</label>
            <v-select
              id="role"
              v-model="payloadDeleteService.services"
              :reduce="(service) => service.id"
              label="name"
              :options="servicesDeleteOption"
              class="invoice-filter-select"
              multiple
            >
              <template v-slot:no-options>
                <b-spinner
                  v-if="isServiceDeleteLoading"
                  style="width: 2.5rem; height: 2.5rem"
                  class="align-middle text-info"
                />

                <span v-else class="mt-1 font-medium-1"
                  >Aucun service
                </span>
              </template>
            </v-select>
          </validation-provider>
          
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isDeleteServiceLoading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <div v-if="isDeleteServiceLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Supprimer</span>
            </b-button>
            <b-button
              type="reset"
              variant="outline-secondary"
              @click="$bvModal.hide('modal-formation')"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

      
  </div>

  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BRow,
  BFormTextarea,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BTableLite,
  BMedia,
  BAvatar,
  // BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BSpinner,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BCardText,
  BCardBody,
  BCardHeader,
  BFormSelect,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import flatPickr from "vue-flatpickr-component";
import formation from "@/store/formation";

export default {
  components: {
    BTab,
    BTabs,
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BPagination,
    BTooltip,
    BSpinner,
    flatPickr,
    BFormGroup,
    BFormInvalidFeedback,
    BForm,
    vSelect,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BTableLite,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BCardText,
    BFormSelect,
    BCardBody,
    BCardHeader,
  },

  data() {
    return {
      formationData: {},
      isServiceLoading: false,
      isDeleteServiceLoading: false,
      isAddServiceLoading: false,
      payloadAddService:  {

      },
      payloadDeleteService:  {

      },
      isServiceDeleteLoading: false,
      servicesOption:[],
      servicesDeleteOption:[],
      payloadFilter: {
        employee_id: "",
        service_id: "",
        status: "",
      },

      employeeOptions: [],

      recurringServicesOption: [],
      statusOptions: [
        {
          label: "En formation",
          value: 1,
        },
        {
          label: "Formé",
          value: 2,
        },
      ],
      formation: [
        {
          certificate: "",
        },
      ],
      isFormationDateRangeLoading: false,
      formation_started_date: null,
      formation_end_date: null,
      rangeDate: null,

      isReLoadFilterDataUnformedEmployee: false,
      isReLoadDataUnformedEmployees: false,
      isMarkAsFormedLoading: false,
      config: { mode: "range" },
      formationId: null,
      required,
      practice_formation_received: null,
      observation: "",
      numbers_days_of_formation: "",
      currentOrder: {},
      customerOptions: [],
      total: "",
      formedEmployee: {},
      clickedIndex: null,
      rows: [],
      EmployeeDatails: null,
      columns: [
        {
          label: "Employée",
          field: "employee",
        },
        {
          label: "Service(s)",
          field: "services",
        },
        {
          label: "Nombre de jours de formation",
          field: "numbers_days_of_formation",
        },

        {
          label: "Status",
          field: "setStatus",
        },
        {
          label: "Actions",
          field: "actions",
        },
      ],
      employeeOptions: [],
      isContractFileDownloading: false,
      avatarText,
      fields: {
        "Nom complet": "Nom complet",
        Age: "Age",
        Téléphone: "Téléphone",
        "Adresse de résidence": "Adresse de résidence",
        "Adresse mail": "Adresse mail",
        "Services fournies": "Services fournies",
        "Statut de l'employé": "Statut de l'employé",
      },
    };
  },

  computed: {
    ...mapGetters("formation", ["getUnformedEmployees"]),
    ...mapGetters("professionals", ["getEmployeesWithoutPagination"]),
    ...mapGetters("customers", ["getCustomersWithoutPagination"]),
    ...mapGetters([
      "isUnformedEmployeesLoading",
      "isEmployeesWithoutPaginationLoading",
      "isCustomersWithoutPaginationLoading",
      "isServicesWithoutPaginationLoading",
      "isServicesRecurringWithoutPaginationLoading",
    ]),
    ...mapGetters("auth", ["getUser"]),
    ...mapGetters("services", [
      "getRecurringServices",
      "getRecurringServicesWithoutPagination",
    ]),

    metaData() {
      const meta = {
        prev_page_url: "",
        next_page_url: "",
        current_page: "",
        last_page: "",
        current_page_url: "",
      };
      if (this.getUnformedEmployees != null) {
        meta.prev_page_url = this.getUnformedEmployees.prev_page_url;
        meta.next_page_url = this.getUnformedEmployees.next_page_url;
        meta.current_page = this.getUnformedEmployees.current_page;
        meta.last_page = this.getUnformedEmployees.last_page;
        meta.current_page_url = `${this.getUnformedEmployees.path}?page=${this.getUnformedEmployees.current_page}`;
        this.total = this.getUnformedEmployees.total;
      }
      return meta;
    },
  },

  watch: {
    rangeDate(val) {
      if (val != null && val.split("to").length === 2) {
        this.applyGetFormationDateRangeAction(val);
      }
    },

    isUnformedEmployeesLoading(val) {
      if (val === false) {
        this.rows = this.customTab(this.getUnformedEmployees.data);
      }
    },
    isCustomersWithoutPaginationLoading(val) {
      if (val === false) {
        this.getCustomersWithoutPagination.forEach((element) => {
          this.customerOptions.push({
            id: element.id,
            name: element.customer_full_name,
          });
        });
      }
    },
    isServicesRecurringWithoutPaginationLoading(val) {
      if (val === false) {
        this.getRecurringServicesWithoutPagination.forEach((element) => {
          this.recurringServicesOption.push({
            id: element.id,
            name: element.name,
          });
        });
      }
    },

    payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue);
        keys.forEach((key) => {
          if (newValue[key] == null) {
            newValue[key] = "";
            console.log(this.payloadFilter);
          }
        });
      },
      deep: true,
    },

    isEmployeesWithoutPaginationLoading(val) {
      if (val === false) {
        this.getEmployeesWithoutPagination.forEach((element) => {
          this.employeeOptions.push({
            id: element.id,
            name: element.full_name,
          });
        });
      }
    },
  },

  mounted() {
    if (this.getRecurringServicesWithoutPagination.length != 0) {
      this.getRecurringServicesWithoutPagination.forEach((element) => {
        this.recurringServicesOption.push({
          id: element.id,
          name: element.name,
        });
      });
    }
  },

  created() {
    console.log("Employés formés", this.formedEmployee);
    this.reLoadDataUnformedEmployeesAction();
    this.getUnformedEmployees.data != null
      ? (this.rows = this.customTab(this.getUnformedEmployees.data))
      : null;
    if (this.getEmployeesWithoutPagination.length != 0) {
      this.getEmployeesWithoutPagination.forEach((element) => {
        this.employeeOptions.push({
          id: element.id,
          name: element.full_name,
        });
      });
    }
  },

  methods: {
    ...mapActions("formation", [
      "getUnformedEmployeesAction",
      "filterUnformedEmployeesAction",
      "markEmployeeAsFormedAction",
      "loadMoreUnformedEmployeesAction",
      "addServiceToFormationAction",
      "deleteFormationServicesAction",
      "getEmployeeServicesAction",
      "getEmployeeDeleteServicesAction"
    ]),
    ...mapActions("professionals", [
    "getEmployeesDetailsAction",
    ]),

    applygetEmployeeService(item){
      this.isServiceLoading = true
      this.servicesOption = []
      this.getEmployeeServicesAction(item.employee_id)
      .then(response =>{
        this.isServiceLoading= false
        console.log(response.data)
        const filteredData = response.data.filter(element => 
        element.formation === null || element.formation.status === 0);
        filteredData.forEach((element) =>{
          this.servicesOption.push(
            {
              id : element.id,
              name : element.recurring_service.name
            }
          )
          
        })
        console.log(this.servicesOption)
      })
      .catch(error =>{
        this.isServiceLoading = false
        console.log(error)
      })
    },

    applyGetFormationDateRangeAction(val) {
      this.formation_started_date = val.split("to")[0];
      this.formation_end_date = val.split("to")[1];
    },

    applyFilterEmployeesUnformedAction() {
      const allIsNUl = Object.values(this.payloadFilter).every(
        (el) => el === ""
      );
      if (allIsNUl) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Warning",
              icon: "CheckIcon",
              text: "Veuillez sélectionner au moins un champ du filtre.",
              variant: "warning",
            },
          },
          {
            position: "top-center",
          }
        );
      } else {
        this.isReLoadFilterDataUnformedEmployee = true;
        this.rows = [];
        this.filterUnformedEmployeesAction(this.payloadFilter)
          .then((response) => {
            this.isReLoadFilterDataUnformedEmployee = false;
            this.rows = this.customTab(this.getUnformedEmployees);
            this.total = this.rows.length;
          })
          .catch((error) => {
            console.log(error);
            this.isReLoadFilterDataUnformedEmployee = false;
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "danger",
                  icon: "AlertTriangleIcon",
                  text: error.response.data.message,
                  variant: "danger",
                },
              },
              {
                position: "top-center",
              }
            );
          });
      }
    },

    reLoadDataUnformedEmployeesAction() {
      this.payloadFilter.employee_id = "";
      this.payloadFilter.service_id = "";
      this.payloadFilter.status = "";
      this.isReLoadDataUnformedEmployees = true;
      this.$store.commit("SET_IS_UNFORMED_EMPLOYEES_LOADING", true);
      this.rows = [];

      this.getUnformedEmployeesAction()
        .then((response) => {
          this.formation = response.data.data;
          console.log("Formations", this.formation);
          this.isReLoadDataUnformedEmployees = false;
          this.$store.commit("SET_IS_UNFORMED_EMPLOYEES_LOADING", false);
        })
        .catch((error) => {
          this.isReLoadDataUnformedEmployees = false;
          this.$store.commit("SET_IS_UNFORMED_EMPLOYEES_LOADING", false);
        });
    },
    showFormationModal(item){
      this.formationData = item
      console.log(item)
      this.$bvModal.show('modal-formation');
    },
    addServiceModal(item){
      this.applygetEmployeeService(item)
      this.formationData = item
      console.log(item)
      this.$bvModal.show('modal-add-service');
    },
    hideModalAddService(){
      this.$bvModal.hide('modal-add-service');
      this.payloadAddService ={

      }
    },
    applyAddService(){
      this.isAddServiceLoading = true
      this.addServiceToFormationAction({
        formationId : this.formationData.id,
        payload: this.payloadAddService
      })
      .then(response =>{
        this.isAddServiceLoading = false
        console.log(response.data)
        this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Succès",
                    icon: "CheckIcon",
                    text: response.message,
                    variant: "success",
                  },
                },
                {
                  position: "top-center",
                }
              );
              this.hideModalAddService()
              this.reLoadDataUnformedEmployeesAction()

      })
      .catch(error =>{
        this.isAddServiceLoading = false
        console.log(error)
        this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Attention",
                    icon: "CheckIcon",
                    text: `${error.response.data.message}`,
                    variant: "warning",
                  },
                },
                {
                  position: "top-center",
                }
              );
      })
    },

    deleteService(item){
      this.servicesDeleteOption = []
      this.isServiceDeleteLoading = true
      console.log(item)
      this.formationData = item
      this.getEmployeeDeleteServicesAction(item.employee_id)
      .then(response =>{
        this.isServiceDeleteLoading = false
        console.log(response.data)
        response.data.forEach((element) =>{
        this.servicesDeleteOption.push({
          id: element.id,
          name: element.recurring_service.name
        })
      })
      })
      .catch(error =>{
        this.isServiceDeleteLoading = false
        console.log(error)
      })
      
      
      this.$bvModal.show('modal-delete-service');
    },
    hideModalDeleteService(){
      this.$bvModal.hide('modal-delete-service');
      this.payloadDeleteService = {

      }
    },
    applyDeleteService(){
      this.isDeleteServiceLoading = true
      this.deleteFormationServicesAction({
        formationId : this.formationData.id,
        payload : this.payloadDeleteService
      }
      )
      .then(response =>{
        this.isDeleteServiceLoading = false
        console.log(response)
        this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Succès",
                    icon: "CheckIcon",
                    text: response.message,
                    variant: "success",
                  },
                },
                {
                  position: "top-center",
                }
              );
              this.hideModalDeleteService()
              this.reLoadDataUnformedEmployeesAction()

      })
      .catch(error =>{
        this.isDeleteServiceLoading = false
        console.log(error)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Attention",
              icon: "CheckIcon",
              text: `${error.response.data.message}`,
              variant: "warning",
            },
          },
          {
            position: "top-center",
          }
        );
      })
    },
    hideModal() {
      (this.serviceId = null),
        (this.formationId = null),
        (this.formation_started_date = null),
        (this.formation_end_date = null),
        (this.rangeDate = null),
        (this.observation = " "),
        (this.observation = " "),
        (this.practice_formation_received = " "),
        (this.numbers_days_of_formation = " ");
      this.$bvModal.hide("modal-formation");
    },

    applyLoadMoreUnformedEmployeesAction(url) {
      this.$store.commit("SET_IS_UNFORMED_EMPLOYEES_LOADING", true);
      this.rows = [];
      this.loadMoreUnformedEmployeesAction(url)
        .then(() => {
          this.$store.commit("SET_IS_UNFORMED_EMPLOYEES_LOADING", false);
        })
        .catch(() => {
          this.$store.commit("SET_IS_UNFORMED_EMPLOYEES_LOADING", false);
        });
    },

    showFeedbackModal(item) {
      this.$bvModal.show("modal-show-feedback-form");
      this.currentOrder = item;
    },

    showEmployeeDetailsModal(item) {
      alert(item)
      this.$bvModal.show("modal-show-employee-profile");
      
      this.formedEmployee = item;
   
    },

    envoi(){
      this.$refs.endTrainingForm.validate().then((success) => {
        if (success) {
        this.$bvModal.show("modal-detail-end-formation")
        }
      
      }  );
    },

    applyMarkEmployeeAsFormedAction() {
      this.$refs.endTrainingForm.validate().then((success) => {
        if (success) {
          const data = {
            practice_formation_received: this.practice_formation_received,
            observation: this.observation,
            numbers_days_of_formation: this.numbers_days_of_formation,
          };

          this.isMarkAsFormedLoading = true;

          this.markEmployeeAsFormedAction({
            formationId: this.formationId,
            payload: data,
          })
            .then((response) => {
              console.log("response", response);
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Succès",
                    icon: "CheckIcon",
                    text: response.message,
                    variant: "success",
                  },
                },
                {
                  position: "top-center",
                }
              );
              this.$bvModal.hide('modal-detail-end-formation');
              this.hideModal();
              this.isMarkAsFormedLoading = false;

              this.clickedIndex = response.data.id;
              this.$router.push({
                path: "/preview/pdf",
                query: {
                  makeActionOnPage: false,
                  actionType: null,
                  pdf: response.certificate,
                },
              });
            })
            .catch((error) => {
              console.log("error", error);
              this.isMarkAsFormedLoading = false;
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Attention",
                    icon: "CheckIcon",
                    text: `${error.response.data.message}`,
                    variant: "warning",
                  },
                },
                {
                  position: "top-center",
                }
              );
            });
        }
      });
    },
    certificatPreview(formation) {
      this.$router.push({
        path: "/preview/pdf",
        query: {
          makeActionOnPage: false,
          actionType: null,
          isSigned: true,
          pdf: formation,
        },
      });
    },
    applyDownloadEmployeeContractAction(id) {
      this.clickedIndex = id;
      this.isContractFileDownloading = true;
      this.downloadEmployeeContractAction(id)
        .then((response) => {
          console.log(response);
          this.isContractFileDownloading = false;
          window.open(`${response.data}`);
        })
        .catch(() => {
          this.isContractFileDownloading = false;
        });
    },
    customTab(array) {
      const tab = [];
      array.forEach((element) => {
        switch (element.status) {
          case 0:
            element.setStatus = "Non Formé";
            break;
          case 1:
            element.setStatus = "En formation";
            break;
          case 2:
            element.setStatus = "Formé";
            break;
        }
        tab.push(element);
      });
      return tab;
    },

    resolveProStatusVariant: (status) => {
      if (status === 0) return "danger";
      if (status === 1) return "warning";
      if (status === 2) return "success";
      return "info";
    },

    getRecurringServicesFromArray(array) {
      let services = "";
      array.forEach((item) => {
        if (item.length !== 0) {
          services += `${item.name}${array.length === 1 ? "" : " | "}`;
        }
      });
      return services;
    },

    showEmployeeDatails(employee) {
      localStorage.setItem("employee", JSON.stringify(employee));
      this.$router.push({
        name: "admin-direct-employee-details",
        params: { id: employee.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
