<template>
  <div
    v-if="
      getUser.role.slug === 'business-developer-pro' ||
        getUser.role.slug === 'rh' ||
        getUser.role.slug === 'assistant-rh' ||
        getUser.role.slug === 'superviseur' ||
        getUser.role.slug === 'super-administrateur'
    "
  >

    <b-tabs
      v-model="tabIndex"
      pills
    >
      <b-tab>
        <template #title>
          <feather-icon
            icon="ListIcon"
            class="text-white mr-1"
            size="20"
          /> Liste des Employés
        </template>
        <hr>
        <b-card>
          <b-card-header class="pb-50">
            <h5>Filtrages</h5>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                md="10"
                lg="10"
                class="mb-md-0 mb-2"
              >
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                    md="4"
                    lg="4"
                  >
                    <label for="">Employée</label>
                    <v-select
                      v-model="payloadFilter.employee_id"
                      :options="employeeOptions"
                      class="invoice-filter-select"
                      label="name"
                      :reduce="(employee) => employee.id"
                    >
                      <template v-slot:no-options>
                        <b-spinner
                          v-if="isEmployeesWithoutPaginationLoading"
                          style="width: 2.5rem; height: 2.5rem"
                          class="align-middle text-info"
                        />
                        <span
                          v-else
                          class="mt-1 font-medium-1"
                        >Aucun employée ne correspond</span>
                      </template>
                    </v-select>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                    md="4"
                    lg="4"
                  >
                    <label for="">Service</label>
                    <v-select
                      v-model="payloadFilter.service_id"
                      :options="recurringServicesOption"
                      class="invoice-filter-select"
                      label="name"
                      :reduce="(service) => service.id"
                    >
                      <template v-slot:no-options>
                        <b-spinner
                          v-if="isServicesWithoutPaginationLoading"
                          style="width: 2.5rem; height: 2.5rem"
                          class="align-middle text-info"
                        />
                        <span
                          v-else
                          class="mt-1 font-medium-1"
                        >Aucun service ne correspond</span>
                      </template>
                    </v-select>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                    md="3"
                    lg="3"
                  >
                    <label for="">Status</label>
                    <v-select
                      v-model="payloadFilter.status"
                      :options="statusOptions"
                      class="invoice-filter-select"
                      :reduce="(item) => item.value"
                    >
                      <template #selected-option="{ label }">
                        <span class="text-truncate overflow-hidden">
                          {{ label }}
                        </span>
                      </template>
                    </v-select>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                    md="3"
                    lg="3"
                  >
                    <label for="">Programme YLOMI</label>
                    <v-select
                      v-model="payloadFilter.is_in_ylomi_program"
                      :options="isInYlomiProgramOptions"
                      class="invoice-filter-select"
                      :reduce="(item) => item.value"
                    >
                      <template #selected-option="{ label }">
                        <span class="text-truncate overflow-hidden">
                          {{ label }}
                        </span>
                      </template>
                    </v-select>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                    md="2"
                    lg="2"
                    class="mt-2"
                  >
                    <b-button
                      variant="primary"
                      @click="applyFilterEmployeesAction()"
                    >
                      <div v-if="isReLoadFilterDataEmployees">
                        <span> Chargement ... </span>
                        <b-spinner small />
                      </div>

                      <span
                        v-else
                        class="text-nowrap font-medium-1"
                      >Filtrer</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <b-card>
          <div class="m-2">
            <b-row class="mb-3">
              <!-- register employee -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-2"
              >
                <b-button
                  variant="primary"
                  :to="{ name: 'admin-direct-employees-new' }"
                >
                  <span class="text-nowrap">Inscrire un employé</span>
                </b-button>
              </b-col>
              <!-- Export employees list -->
              <!-- <b-col
              cols="12"
              md="3"
          >
            <download-excel
                :fields="fields"
                :data="jsonData"
                name="liste-des-employes.xls"
            >
              <b-button variant="success" :disabled="rows.length === 0">
                Exporter en Excel
              </b-button>
            </download-excel>
          </b-col> -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-end mb-2"
              >
                <b-button
                  variant="primary"
                  @click="applyGetEmployeesAction()"
                >
                  <div v-if="isLoading">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
                  <span
                    v-else
                    class="text-nowrap font-medium-1"
                  >Actualiser</span>
                </b-button>
              </b-col>
            </b-row>

            <!-- Filter by BDP -->
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <v-select
                  v-if="getUser.role.name == 'Super Administrateur'"
                  v-model="bdpFilter"
                  :options="bdpListOptions"
                  class="invoice-filter-select"
                  placeholder="Filtrer par BDP"
                  :reduce="(item) => item.value"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
              </b-col>
            </b-row>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :is-loading="isEmployeesLoading"
          >
            <template slot="emptystate">
              <div class="text-center text-info">
                <span> {{ isEmployeesLoading ? "" : "Liste vide" }}</span>
              </div>
            </template>

            <template slot="loadingContent">
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle" /> &nbsp;
              </div>
            </template>

            <template
              slot="table-row"
              slot-scope="props"
            >
              <div
                v-if="props.column.field == 'full_name' && props.row.phone_number"
                class="align-middle text-center"
              >
                <span class="d-block font-small-5 text-info text-nowrap">
                  {{ props.row.full_name }}
                </span>
                <span class="text-success font-small-4">{{
                  props.row.phone_number
                }}</span>
                <span class="d-block  font-small-4">{{
                  props.row.address
                }}</span>
              </div>

              <div
                v-if="props.column.field == 'recurring_services'"
                class="align-middle text-center"
              >
                <ul>
                  <li
                    v-for="(item, index) in props.row.recurring_services"
                    :key="index"
                  >
                    <span class="text-info font-small-5">
                      {{ item.name }}
                    </span>
                  </li>
                </ul>
              </div>

              <div
                v-if="props.column.field == 'saved_by' && props.row.saved_by != null
                "
                class="align-middle text-center"
              >
                <span
                  class="text-info font-small-5"
                  :class="props.row.saved_by != null ? 'text-info' : 'text-danger'"
                >
                  {{
                    props.row.saved_by != null
                      ? props.row.saved_by.full_name
                      : "Non assignée"
                  }}
                </span>
              </div>
              <div
                v-if="props.column.field == 'setStatus'"
                class="align-middle text-center"
              >
                <b-badge
                  pill
                  :variant="`light-${resolveProStatusVariant(props.row.status)}`"
                  class="text-capitalize font-small-5"
                >
                  {{ props.row.setStatus }}
                </b-badge>
              </div>
              <!-- action -->
              <div v-else-if="props.column.field == 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="20"
                        class="text-info align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      class="cursor-pointer itemActions"
                      @click="showEmployeeDetails(props.row)"
                    >
                      <feather-icon
                        :id="`details${props.row.id}`"
                        icon="EyeIcon"
                        class="text-info mr-1"
                        size="25"
                      />
                      <span>Détails</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      class="cursor-pointer itemActions"
                      @click="applyDeleteEmployeeAction(props.row.id)"
                    >
                      <feather-icon
                        :id="`delete${props.row.id}`"
                        icon="TrashIcon"
                        class="text-info mr-1"
                        size="25"
                      />
                      <span>Supprimer</span>
                    </b-dropdown-item>
                    <!-- <b-dropdown-item v-if="props.row.status == 2" class="cursor-pointer itemActions"
                      @click="applyDownloadEmployeeContractAction(props.row.id)">
                      <feather-icon :id="`assign${props.row.id}`" icon="DownloadIcon" class="text-info mr-1"
                        size="25" />
                      <span>Contrat de l'employé</span>
                    </b-dropdown-item> -->
                  </b-dropdown>
                </span>
              </div>
            </template>
          </vue-good-table>

          <div class="d-flex justify-content-between mt-2 flex-wrap">
            <b-button
              :disabled="metaData.prev_page_url == null"
              variant="outline-secondary"
              @click="applyLoadMoreEmployeesAction(metaData.prev_page_url)"
            >
              <span class="text-white text-nowrap font-medium-2">Page précédente</span>
            </b-button>

            <div>
              <span class="text-white font-medium-2">{{ metaData.current_page }} sur
                {{ metaData.last_page }} pages</span>
            </div>
            <div>
              <span class="text-white font-medium-2">Total : {{ total }}</span>
            </div>
            <b-button
              variant="outline-secondary"
              :disabled="metaData.next_page_url == null"
              @click="applyLoadMoreEmployeesAction(metaData.next_page_url)"
            >
              <span class="text-nowrap font-medium-1 text-white">Page suivante</span>
            </b-button>
          </div>
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="ListIcon"
            class="text-white mr-1"
            size="20"
          /> Liste employés en formation
        </template>
        <hr>
        <formation />
      </b-tab>
    </b-tabs>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  // BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BSpinner,
  // BTabs,
  // BAlert,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import formation from '@/views/formation/formation.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    // BAlert,
    // BLink,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BSpinner,
    BTab,
    BTabs,
    vSelect,
    VueGoodTable,
    formation,
  },
  data() {
    return {
      tabIndex: 0,
      suspend_reason: '',
      payloadFilter: {
        employee_id: '',
        service_id: '',
        status: '',
        is_in_ylomi_program: '',
      },

      employeeOptions: [],

      recurringServicesOption: [],

      isReLoadFilterDataEmployees: false,
      isInYlomiProgramOptions: [
        {
          label: 'Participe',
          value: true,
        },
        {
          label: 'Ne Participe pas',
          value: false,
        },
      ],
      statusOptions: [
        {
          label: 'En attente de validation',
          value: 0,
        },
        {
          label: 'En attente de confirmation',
          value: 1,
        },
        {
          label: 'Confirmé',
          value: 5,
        },
        {
          label: 'Occupé(E)',
          value: 2,
        },
        {
          label: 'Suspendu(E)',
          value: 3,
        },
        {
          label: 'Candidature spontané(E)',
          value: 4,
        },
      ],

      clickedIndex: null,
      isLoading: false,

      rows: [],
      columns: [
        {
          label: 'Identité',
          field: 'full_name',
        },
        {
          label: 'Service(s)',
          field: 'recurring_services',
        },
        {
          label: 'Statut',
          field: 'setStatus',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],

      jsonData: [],
      total: '',
      bdpListOptions: [],
      bdpFilter: null,
    }
  },
  computed: {
    ...mapGetters('professionals', [
      'getEmployees',
      'getEmployeesWithoutPagination',
    ]),
    ...mapGetters([
      'isEmployeesLoading',
      'isEmployeesWithoutPaginationLoading',
      'isServicesWithoutPaginationLoading',
      'isServicesRecurringWithoutPaginationLoading',
    ]),
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('services', [
      'getRecurringServices',
      'getRecurringServicesWithoutPagination',
    ]),

    metaData() {
      const meta = {
        prev_page_url: '',
        next_page_url: '',
        current_page: '',
        last_page: '',
        current_page_url: '',
      }
      if (this.getEmployees != null) {
        meta.prev_page_url = this.getEmployees.prev_page_url
        meta.next_page_url = this.getEmployees.next_page_url
        meta.current_page = this.getEmployees.current_page
        meta.last_page = this.getEmployees.last_page
        meta.current_page_url = `${this.getEmployees.path}?page=${this.getEmployees.current_page}`
        this.total = this.getEmployees.total
      }
      return meta
    },
  },
  watch: {
    isEmployeesLoading(val) {
      if (val === false) {
        this.rows = this.customTab(this.getEmployees.data)
      }
    },

    payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue)
        keys.forEach(key => {
          if (newValue[key] == null) {
            newValue[key] = ''
            console.log(this.payloadFilter)
          }
        })
      },
      deep: true,
    },

    isServicesRecurringWithoutPaginationLoading(val) {
      if (val === false) {
        this.getRecurringServicesWithoutPagination.forEach(element => {
          this.recurringServicesOption.push({
            id: element.id,
            name: element.name,
          })
        })
      }
    },

    isEmployeesWithoutPaginationLoading(val) {
      if (val === false) {
        this.getEmployeesWithoutPagination.forEach(element => {
          this.employeeOptions.push({
            id: element.id,
            name: element.full_name,
          })
        })
      }
    },
    bdpFilter(newVal, oldVal) {
      if (newVal !== null) {
        this.applyGetBdpSavedEmployeesAction(newVal)
      } else {
        this.applyGetEmployeesAction()
      }
    },
    getEmployees(newValue, oldValue) {
      if (oldValue.length !== 0 && oldValue.length !== newValue.length) {
        this.rows = this.customTab(this.getEmployees.data)
      }
    },

  },
  mounted() {
    if (this.getRecurringServicesWithoutPagination.length != 0) {
      this.getRecurringServicesWithoutPagination.forEach(element => {
        this.recurringServicesOption.push({
          id: element.id,
          name: element.name,
        })
      })
    }

    if (this.getEmployeesWithoutPagination.length != 0) {
      this.getEmployeesWithoutPagination.forEach(element => {
        this.employeeOptions.push({
          id: element.id,
          name: element.full_name,
        })
      })
    }

    if (this.getUser.role.name === 'Super Administrateur' || this.getUser.role.slug === 'rh') {
      this.columns.splice(2, 0, {
        label: 'Enregistré par',
        field: 'saved_by',
        filterOptions: {
          enabled: true,
          placeholder: 'Rechercher...',
          filterFn(data, filterString) {
            return data.full_name
              .toLowerCase()
              .includes(filterString.toLowerCase())
          },
        },
      })
    }
  },
  created() {
    this.tabIndex = this.$route.query.tabIndex == '1' ? 1 : 0
    this.getEmployees.data != null
      ? (this.rows = this.customTab(this.getEmployees.data))
      : null
    this.applyGetBusinessDevelopperListAction()
  },
  methods: {
    ...mapActions('roles', ['getBusinessDeveloperListAction']),
    ...mapActions('professionals', [
      'getEmployeesAction',
      'deleteEmployeeAction',
      'downloadEmployeeContractAction',
      'getBdpSavedEmployeesAction',
      'loadMoreEmployeesAction',
      'filterEmployeesAction',
    ]),

    ...mapActions('formation', [
      'getUnformedEmployeesAction',
      'filterUnformedEmployeesAction',
      'markEmployeeAsFormedAction',
      'loadMoreUnformedEmployeesAction',
    ]),

    applyFilterEmployeesAction() {
      const allIsNUl = Object.values(this.payloadFilter).every(
        el => el === '',
      )
      if (allIsNUl) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Warning',
              icon: 'CheckIcon',
              text: 'Veuillez sélectionner au moins un champ du filtre.',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else {
        this.isReLoadFilterDataEmployees = true
        this.rows = []
        this.filterEmployeesAction(this.payloadFilter)
          .then(response => {
            this.isReLoadFilterDataEmployees = false
            this.rows = this.customTab(this.getEmployees)
            this.total = this.rows.length
          })
          .catch(error => {
            console.log(error)
            this.isReLoadFilterDataEmployees = false
          })
      }
    },
    showEmployeeSuspendReason(item) {
      this.$bvModal.show('modal-show-suspend-reason')
      this.suspend_reason = item
    },
    customTab(array) {
      const tab = []
      array.forEach(element => {
        switch (element.status) {
          case 0:
            element.setStatus = 'En attente de validation'
            break
          case 1:
            element.setStatus = 'En attente de confirmation'
            break
          case 2:
            element.setStatus = 'Occupé(E)'
            break
          case 3:
            element.setStatus = 'Suspendu(E)'
            break
          case 4:
            element.setStatus = ' Candidature spontané(E)'
            break
          case 5:
            element.setStatus = 'Confirmé'
            break

          default:
            element.setStatus = null
        }
        tab.push(element)
      })
      return tab
    },

    applyGetEmployeesAction() {
      this.isLoading = true
      this.$store.commit('SET_IS_EMPLOYEES_LOADING', true)
      this.rows = []
      this.getEmployeesAction()
        .then(() => {
          console.log('ROWS', this.rows)
          this.isLoading = false
          this.$store.commit('SET_IS_EMPLOYEES_LOADING', false)
        })
        .catch(() => {
          this.isLoading = false
          this.$store.commit('SET_IS_EMPLOYEES_LOADING', false)
        })
    },

    applyLoadMoreEmployeesAction(url) {
      this.$store.commit('SET_IS_EMPLOYEES_LOADING', true)
      this.rows = []
      this.loadMoreEmployeesAction(url)
        .then(() => {
          this.$store.commit('SET_IS_EMPLOYEES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_EMPLOYEES_LOADING', false)
        })
    },

    applyGetBdpSavedEmployeesAction(id) {
      this.$store.commit('SET_IS_EMPLOYEES_LOADING', true)
      this.getBdpSavedEmployeesAction(id)
        .then(() => {
          this.$store.commit('SET_IS_EMPLOYEES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_EMPLOYEES_LOADING', false)
        })
    },

    applyGetBusinessDevelopperListAction() {
      this.getBusinessDeveloperListAction().then(response => {
        response.data.forEach(item => {
          this.bdpListOptions.push({ label: item.full_name, value: item.id })
          console.log(this.bdpListOptions)
        })
      })
    },

    applyDeleteEmployeeAction(id) {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer cet(te) employé(e) ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteEmployeeAction(id)
          // eslint-disable-next-line no-return-await
          this.applyLoadMoreEmployeesAction(this.metaData.current_page_url)
        },
      })
        .then(response => {
          if (response.value) {
            this.applyLoadMoreEmployeesAction(this.metaData.current_page_url)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'employé(e) supprimé avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyDownloadEmployeeContractAction(id) {
      this.clickedIndex = id
      this.isContractFileDownloading = true
      this.downloadEmployeeContractAction(id)
        .then(response => {
          console.log('response', response)
          this.isContractFileDownloading = false
          this.$router.push({
            path: '/preview/pdf',
            query: {
              makeActionOnPage: false, actionType: null, isSigned: true, orderId: response.orderId, pdf: response.data,
            },
          })
        })
        .catch(error => {
          console.log('error', error)
          this.isContractFileDownloading = false
          this.$router.push({
            path: '/preview/pdf',
            query: {
              makeActionOnPage: true, actionType: 'signature', isSigned: false, orderId: error.response.data.orderId, pdf: error.response.data.data,
            },
          })
        })
    },

    resolveProStatusVariant: status => {
      if (status === 0) return 'warning'
      if (status === 1) return 'white'
      if (status === 5) return 'success'
    },

    showEmployeeDetails(employee) {
      this.$store.commit('professionals/SET_EMPLOYEE_DETAILS', employee)
      this.$router.push({
        name: 'admin-direct-employee-details',
        params: { id: employee.id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.itemActions :hover {
  background: transparent !important;
}

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
.vgt-select {
  background: transparent !important;
  border-color: #404656 !important;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
